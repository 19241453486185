const components = import.meta.glob(
  "../../views/components/**/index.js", {eager: true}
)

console.log('Register View Components')

for (let path in components) {
  let module = components[path]
  console.log(`-- ${path}`)
}
